//*for live api use uncomment============>>>>/*
export const environment = {
  production: true,
  apiUrl:'https://partnerapi.fareboutique.com/v1/',
  url:'https://partnerapi.fareboutique.com/',
  imageurl:'https://api.fareboutique.com/uploads/airlines/',
  xkey:'1ZZN6I1659527151925'
};


  //*for Dev api use uncomment============>>>>*/
  // export const environment = {
  //   production: false,
  //   apiUrl:'https://devpatnerapi.fareboutique.com/v1/',
  //   url:'https://devpatnerapi.fareboutique.com/',
  //   imageurl:'https://devapi.fareboutique.com/uploads/airlines/',
  //   xkey:'1FMQKB1639407126571'
  // };